import Service from './Service';

const resource = '/api/citas';

export default {
    atendida(id){
        return Service.put(`${resource}/atendida/${id}`);
    },
    last(id_usuario){
        return Service.get(`${resource}/last/${id_usuario}`);
    },
    get(id){
        return Service.get(`${resource}/${id}`);
    },
    show(id){
        return Service.get(`${resource}/show/${id}`);
    },
    index(search = '', pagination = {}){

        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(pagination['per_page'] ? {per_page : pagination['per_page']} : {}),
            ...(pagination['page'] ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };

        return Service.get(`${resource}`, {
            params : {
                ...params
            }
        });
    },
}
