<template>
  <main>
    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-5">
      <div class="container-xl px-4">
        <div class="page-header-content pt-4">
          <div class="row align-items-center justify-content-between">
            <div class="col-auto mt-4">
              <h2 class="page-header-title">
                Historia Clínica - Perfil de Usuario
              </h2>
            </div>
          </div>
        </div>
      </div>
    </header>
    <br>
    <div class="container-xl px-4 mt-n10">

      <div class="row" v-if="historia.finalizada === 'N'">
        <div class="col-12">
          <div class="alert alert-warning">
            <i class="fa fa-exclamation-triangle fa-fw"></i> Este usuario tiene una historia de <span class="font-weight-bolder">{{ historia.tipo_historia.nombre }}</span> sin finalizar, favor
            finalizar para crear una nueva.
          </div>
        </div>
      </div>

      <div v-if="historia.finalizada === 'N'">
        <div class="card" v-if="historia.tipo_historia && historia.tipo_historia.codigo === 'URG' && historia.ingreso==='finalizado'">
          <div class="card-body">
            <div class="row" v-if="_can('opciones.urgencia')">
              <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                <a class="card h-100 lift shadow-none border-warning-soft bg-warning-soft" @click="ordenamiento();">
                  <div class="card-body d-flex flex-column">
                      <div class="text-center ">
                          <div class="mb-3">
                            <i class="fas fa-list-alt fa-fw fa-2x text-warning mb-2 mt-3"></i>
                            <h6  class="text-h6">Ver ordenes Médicas</h6>
                          </div>
                      </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
                <a class="card h-100 lift shadow-none border-blue-soft bg-blue-soft" @click="ordenesProcedimiento()">
                  <div class="card-body d-flex flex-column">
                      <div class="text-center ">
                          <div class="mb-3">
                            <i class="fas fa-clipboard-list fa-fw fa-2x text-primary mb-2 mt-3"></i>
                            <h6  class="text-h6">Ordenes de Procedimiento</h6>
                          </div>
                      </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
                <a class="card h-100 lift shadow-none border-info-soft bg-info-soft cursor" @click="ordenesMedicamento();">
                  <div class="card-body d-flex flex-column">
                      <div class="text-center ">
                          <div class="mb-3">
                            <i class="fas fa-prescription-bottle-alt fa-fw fa-2x text-info mb-2 mt-3"></i>
                            <h6  class="text-h6">Ordenes de Medicamentos</h6>
                          </div>
                      </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
                <a class="card h-100 lift shadow-none border-primary-soft bg-primary-soft" @click="formulas();">
                  <div class="card-body d-flex flex-column">
                      <div class="text-center">
                          <div class="mb-3">
                            <i class="fas fa-capsules fa-fw fa-2x text-primary mb-2 mt-3"></i>
                            <h6  class="text-h6">Fórmula Médica</h6>
                          </div>
                      </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 text-center">
                <a class="card h-100 lift  shadow-none border-danger-soft bg-danger-soft" @click="evoluciones();">
                  <div class="card-body d-flex flex-column">
                      <div class="text-center">
                          <div class="mb-3">
                            <i class="fas fa-clipboard-check fa-fw fa-2x text-danger mb-2 mt-3"></i>
                            <h6  class="text-h6">Evoluciones</h6>
                          </div>
                      </div>
                  </div>
                </a>
              </div>

              <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
                <a class="card h-100 lift shadow-none border-success-soft bg-success-soft" data-target="#modalRemisionesPerfil" data-toggle="modal">
                  <div class="card-body d-flex flex-column">
                      <div class="text-center">
                          <div class="mb-3">
                            <i class="fas fa-file-medical fa-fw fa-2x text-success mb-2 mt-3"></i>
                            <h6  class="text-h6">Remisiones</h6>
                          </div>
                      </div>
                  </div>
                </a>
                <modal-remision-perfil :idHistoria="historia.id" :usuario="usuario"></modal-remision-perfil>
              </div>
            </div>
            <div v-else>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <a class="card h-100 lift shadow-none border-warning-soft bg-warning-soft" @click="ordenamiento();">
                  <div class="card-body d-flex flex-column">
                      <div class="text-center ">
                          <div class="mb-3">
                            <i class="fas fa-list-alt fa-fw fa-2x text-warning mb-2 mt-3"></i>
                            <h6  class="text-h6">Ver ordenes Médicas</h6>
                          </div>
                      </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-xl-4 mt-2">

          <!-- informacion usuario card-->
          <div class="card mt-4"  v-show="!Object.keys(usuario).length">
            <div class="card-body">
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="card" v-show="Object.keys(usuario).length">
            <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
            <div class="card-body">
            
                <div class="row">
                  <div class="col-lg-2">
                    <div class="bg-light avatar avatar-xxl" :class="{'avatar-online':usuario.activo === 'S','avatar-busy':usuario.activo === 'N'}">
                      <img v-if="usuario.sexo === 'F'" class="avatar-img img-fluid" src="@/assets/img-genero/mujer.png" >
                      <img v-if="usuario.sexo === 'M'" class="avatar-img img-fluid" src="@/assets/img-genero/hombre.png" >
                    </div>
                  </div>
                  <div class="col-lg-10">
                    <h6 class="mx-1 px-2 mt-2">
                      {{ usuario.nombre_1 }} {{ usuario.nombre_2 }} {{ usuario.apellido_1 }}
                      {{ usuario.apellido_2 }}
                    </h6>
                  </div>
                <div class="col-lg-12">
                  <div class="card-text mb-1">
                    <span class="text-primary">F. Nac. {{ usuario.fecha_nacimiento | formatDate }} </span>
                    <span v-if="usuario.tipo_documento" class="font-weight-bolder">| ({{usuario.tipo_documento.codigo }}) {{usuario.documento}}</span>
                    
                  </div>
                  <span class="badge badge-success-soft text-black badge-marketing">{{usuario.years}}</span>
                  <div class="bg-light rounded mt-2">
                    <div class="col-lg-12">
                      <label class="small font-italic mt-2"><i class="fas fa-hospital-alt text-primary"></i> {{eps}}</label>
                    </div>
                </div>

                <div class="d-flex justify-content-end mt-2">
                  <button class="btn btn-outline-dark btn-xs" data-toggle="modal"
                  data-target="#perfilUsuario">Ver más <i class="fa fa-eye"></i></button>
                  <router-link :to="{ name: 'usuario.historial.historias', params: { id: usuario.id } }"
                    class="btn btn-secondary btn-sm shadow-sm mx-2">
                    Historial H.C <i class="fas fa-file-medical fa-fw"></i>
                  </router-link>
                </div>
              </div>
              </div>
            </div>
          </div>
          <!-- apgar familiar card-->
          <div >
            <div class="card mt-3"  v-show="!Object.keys(usuario).length">
              <div class="card-body">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="false" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="card mb-4 mt-3" v-show="Object.keys(usuario).length">
              <!-- apgar familiar card-->
              <cardApgarFamiliar :idUsuario="usuario.id" :usuario="usuario"></cardApgarFamiliar>
            </div>
          </div>

          <div >
            <div class="card mt-3"  v-show="!Object.keys(usuario).length">
              <div class="card-body">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="false" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="card mb-4 mt-3" v-show="Object.keys(usuario).length">
              <!-- certificate medical card-->
              <cardMedicalCertificate :idUsuario="usuario.id" :usuario="usuario"></cardMedicalCertificate>
            </div>
          </div>

          <!-- embarazo card-->
          <div v-if="usuario.sexo === 'F'">
            <div class="card mt-3"  v-show="!Object.keys(usuario).length">
              <div class="card-body">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="false" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="card mb-4 mt-3" v-show="Object.keys(usuario).length">
              <!-- embarazo card-->
              <cardEmbarazo :idUsuario="usuario.id" :usuario="usuario"></cardEmbarazo>
            </div>
          </div>

        </div>
        <!-- Antecedentes card-->
        <div class="col-xl-5 mt-2">
          <alertAntecedente :usuario="usuario"></alertAntecedente>
          <div>
            <div class="card mt-3"  v-show="!Object.keys(usuario).length">
              <div class="card-body">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="false" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div v-show="Object.keys(usuario).length">
              <cardAntecedente :idUsuario="usuario.id"></cardAntecedente>
            </div>
          </div>

          <!-- citologias card-->
          <div v-if="usuario.sexo === 'F'" >
            <div class="card mt-3"  v-show="!Object.keys(usuario).length">
              <div class="card-body">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="false" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="card mb-4" v-show="Object.keys(usuario).length">
              <!-- citologias card-->
              <card-citologias :id-usuario="usuario.id"></card-citologias>
            </div>
          </div>
        </div>

        <div class="col-xl-3 mt-2">

          <!-- botones card-->
          <div class="card mt-3"  v-show="isLoading">
            <div class="card-body">
              <content-placeholders :rounded="true">
                <content-placeholders-heading :img="false" />
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div class="card mb-4" v-show="!isLoading && _can('create.historia')" >
            <div class="card-body text-center" v-if="Object.entries(triage).length && historia.finalizada !== 'N'">
              <button class="btn btn-primary  btn-lg" type="button" @click="historiaUrgencia();">
                Llenar Historia Urgencia &nbsp;<i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="card-body text-center" v-else-if="historia.finalizada !== 'N'">
              <div v-if="citaId===null" class="alert alert-warning">
                  Para llenar una historia el usuario debe tener una cita confirmada
              </div>
              <div v-else>
                <button class="btn btn-primary  btn-md" type="button"  @click="abrirModalPerfil()" >
                  Llenar Historia <i class="fas fa-plus"></i>
                </button>
              </div>
              
            </div>
            <div class="card-body" v-else-if="historia.finalizada === 'N'">
              <div class="row" v-if="historia.tipo_historia.codigo === 'URG'">
                <div class="col-12 mt-2" v-if="historia.ingreso==='finalizado'">
                  <finalizar-historia :idHistoria="historia.id" :idUsuario="usuario.id" :codigoHistoria="historia.tipo_historia.codigo"></finalizar-historia>
                </div>
                <div class="col-12 mt-2">
                  <button @click="continuarHistoria();" v-if="historia.ingreso !== 'finalizado'"
                    class="btn btn-warning btn-sm" type="button">
                    Continuar Historia &nbsp; <i class="fas fa-check"></i>
                  </button>
                </div>
                <div class="col-12 mt-2" v-if="historia.ingreso==='finalizado'">
                  <incapacidad-component :idHistoria="historia.id" :usuario="usuario"></incapacidad-component>
                </div>
              </div>
              <div v-else class="text-center">
                <button @click="continuarHistoria();" class="btn btn-warning  btn-sm" type="button">
                  Continuar Historia &nbsp; <i class="fas fa-check"></i>
                </button>
              </div>
            </div>
          </div>

          <modalPerfil ref="modalPerfil" :usuario="usuario" :id-cita="citaId" :idTipoHistoria="idTipoHistoria"></modalPerfil>

          <!-- <div v-if="historia.tipo_historia">
            <div class="card" v-if="historia.tipo_historia.codigo === 'URG'">
              <div class="card-body text-center">
                <button @click="ordenamiento();" class="btn btn-warning  btn-md" type="button">
                  Ver Ordenes Médicas <i class="far fa-list-alt fa-fw"></i>
                </button>
              </div>
            </div>

            <div class="card mt-4" v-if="historia.tipo_historia.codigo === 'URG'">
              <div class="card-body text-center">
                <button @click="ordenesMedicamento();" class="btn btn-info  btn-md" type="button">
                  Orden de Medicamentos <i class="fas fa-capsules fa-fw"></i>
                </button>
              </div>
            </div>

            <div class="card mt-4" v-if="historia.tipo_historia.codigo === 'URG'">
              <div class="card-body text-center">
                <button @click="ordenesProcedimiento();" class="btn btn-info  btn-md" type="button">
                  Orden de Procedimientos <i class="fas fa-clipboard-list fa-fw"></i>
                </button>
              </div>
            </div>

            <div class="card mt-4" v-if="historia.tipo_historia.codigo === 'URG'">
              <div class="card-body text-center">
                <button @click="formulas();" class="btn btn-primary  btn-md" type="button">
                  Fórmula Médica  <i class="fas fa-capsules fa-fw"></i>
                </button>
              </div>
            </div>

            <div class="card mt-4" v-if="historia.tipo_historia.codigo === 'URG'">
              <div class="card-body text-center">
                <button @click="evoluciones();" class="btn btn-success  btn-lg" type="button">
                  Evoluciones <i class="fas fa-clipboard-check fa-fw"></i>
                </button>
              </div>
            </div>
          </div> -->

          <!-- notas enfremeria card-->
          <div>
            <div class="card mt-4"  v-show="!Object.keys(usuario).length">
              <div class="card-body">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="false" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="card mt-4" v-show="Object.keys(usuario).length">
              <!-- notas enfremeria card-->
              <cardNotasEnfermeria :idUsuario="id_usuario" :usuario="usuario" ></cardNotasEnfermeria>
            </div>
          </div>

           <!-- notas trabajo social card-->
           <div>
            <div class="card mt-4"  v-show="!Object.keys(usuario).length">
              <div class="card-body">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="false" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div class="card mt-4" v-show="Object.keys(usuario).length">
              <!-- notas trabajo social card-->
              <cardNotasTrabajoSocial :idUsuario="id_usuario" :usuario="usuario" ></cardNotasTrabajoSocial>
            </div>
          </div>

        </div>
      </div>
    </div>
  </main>
</template>

<script>

import usuarioService from '../../../services/usuarioService';
import historiaClinicaService from '../../../services/historiaClinicaService';
import { isEmpty } from 'lodash';
import modalPerfil from './modalPerfil';
import cardAntecedente from "./cardAntecedente";
import cardApgarFamiliar from "../base/baseApgarFamiliar/cardApgarFamiliar";
import cardEmbarazo from "../base/baseEmbarazo/cardEmbarazo";
import cardNotasEnfermeria from "../base/baseNotasEnfermeria/cardNotasEnfermeria";
import urgenciaTriageService from '../../../services/urgenciaTriageService';
import urgenciaTriageHistoriaService from '../../../services/urgenciaTriageHistoriaService';
import tipoHistoriaService from '../../../services/tipoHistoriaService';
import CardCitologias from "@/views/historias/citologia/cardCitologias";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import FinalizarHistoria from '../base/finalizarHistoria/finalizarHistoria';
//import RemisionComponent from "../base/baseRemision/index";
import modalRemisionPerfil from "../base/baseRemision/modalRemisionPerfil";
import Swal from 'sweetalert2';
import incapacidadComponent from "../base/baseIncapacidades/index";
import cardNotasTrabajoSocial from "../base/baseNotaTrabajoSocial/cardNotasTrabajoSocial";
import citasService from "@/services/citasService";
import alertAntecedente from "../base/baseAlertPatient/index";
import cardMedicalCertificate from "../base/baseMedicalCertificate/cardMedicalCertificate";
export default {
  components: {
    CardCitologias,
    modalPerfil, cardAntecedente, cardApgarFamiliar, cardEmbarazo, cardNotasEnfermeria, modalInformacionUsuario,
    FinalizarHistoria,modalRemisionPerfil,incapacidadComponent,cardNotasTrabajoSocial,alertAntecedente,cardMedicalCertificate
  },
  data() {
    return {
      historia: {
        id: '',
        fecha: '',
        id_usuario: '',
        finalizada: ''
      },
      usuario: {},
      triage: {},
      id_usuario: '',
      isLoading: true,
      eps:{},
      citaId: null,
      idTipoHistoria: null,
    }
  },
  watch:{
    'usuario':function(){
      if(isEmpty(this.usuario.contratos)){
        this.eps='Sin datos...';
      }else{
          this.eps = this.usuario.contratos[0].contrato.eps.DESEPS
      }
    }
  },
  methods: {
    ordenesProcedimiento() {
      this.$router.push({
        name: 'ordenes.usuario', params: { id_historia: this.historia.id }
      });
    },
    ordenesMedicamento() {
      this.$router.push({
        name: 'ordenes.medicamento.urgencia.usuario', params: { id_historia: this.historia.id }
      });
    },
    formulas() {
      this.$router.push({
        name: 'formulas.usuario', params: { id_historia: this.historia.id }
      });
    },
    ordenamiento() {
      this.$router.push({
        name: 'ordenamiento', params: { id: this.historia.id }
      });
    },
    evoluciones() {
      this.$router.push({
        name: 'evoluciones.usuario', params: { id_historia: this.historia.id }
      });
    },
    async cargarUsuario() {
      const response = await usuarioService.show(this.id_usuario);
      this.usuario = response.data;

    },
    continuarHistoria() {
      this.$router.push({
        name: this.historia.tipo_historia.path, params: { id: this.historia.id }
      });
    },
    async consultarTriage() {
      //this.LoaderSpinnerShow();
      this.isLoading = true;
      const response = await urgenciaTriageService.showByIdUser(this.id_usuario);
      this.triage = response.data;
      this.isLoading = false;
      //this.LoaderSpinnerHide();
    },
    async historiaUrgencia() {
      try {
        this.LoaderSpinnerShow();
        let hoy = new Date();

        let fechaAct = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate();

        this.historia.fecha = fechaAct;

        const response_tipo_historia = await tipoHistoriaService.index();

        const tipoHistoria = response_tipo_historia.data.find(x => x.codigo === 'URG');

        const historia = {
          fecha: fechaAct,
          id_tipo_historia: tipoHistoria.id,
          id_usuario: this.usuario.id,
          id_eps:this.usuario.contratos?this.usuario.contratos[0].contrato.eps.id:''
        };

        const response = await historiaClinicaService.store(historia);

        const historia_triage = {
          id_historia: response.data.id,
          id_triage: this.triage.id
        }

        await urgenciaTriageHistoriaService.store(historia_triage);

        this.$router.push({
          name: tipoHistoria.path,
          params: {
            id: response.data.id
          }
        });
        this.LoaderSpinnerHide();
      } catch (err) {
        console.error(err);
        this.LoaderSpinnerHide();
        Swal.fire("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
      }
    },
    async historiaFinalizada() {
      //this.LoaderSpinnerShow();
      const response = await historiaClinicaService.showUnfinished(this.id_usuario);
      //this.LoaderSpinnerHide();
      if (!isEmpty(response.data)) {
        this.historia = response.data;

      }

    },
    abrirModalPerfil(){
      this.$refs.modalPerfil.cargarTipoHistoria();
    },
    async init() {

      this.id_usuario = this.$route.params.idUsuario;

      await this.cargarUsuario();

      await this.historiaFinalizada();

      await this.consultarTriage();


      if(this.$route.query?.citaId){
        const { data } = await citasService.show(this.$route.query.citaId);
        console.log(data);
        if(data.estado === 'confirmada' && this.historia.id_cita==null) {
          this.citaId = this.$route.query.citaId;
          this.idTipoHistoria = this.$route.query.idTipoHistoria;
          localStorage.setItem(`appointment_${this.id_usuario}`, this.citaId);
          localStorage.setItem(`historia_${this.id_usuario}`, this.idTipoHistoria);
        }
      }else{
        this.citaId = localStorage.getItem(`appointment_${this.id_usuario}`);
        this.idTipoHistoria = localStorage.getItem(`historia_${this.id_usuario}`);
      }

      if(isEmpty(this.usuario.contratos)){
        this.eps='Sin datos...';
      }else{
          this.eps = this.usuario.contratos[0].contrato.eps.DESEPS
      }

    }
  },
  async mounted() {
   
    await this.init();

    
    //this.$route.query?.idTipoHistoria && (this.idTipoHistoria = this.$route.query.idTipoHistoria);

  }
}
</script>
<style scoped>
.text-h6{
  font-size:14px;
}

a:hover {
  cursor:pointer;
}
</style>
