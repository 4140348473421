<template>
    <main>
        <!-- Modal -->
        <modalNotasList :notas="notas" :usuario="usuario" :idUsuario="idUsuario" ref="listNotas" v-on:ultima-nota="cargarUltimaNota(idUsuario)"></modalNotasList>  
        <!-- Modal -->
        <modalNotasCreate :notas="notas" :usuario="usuario" :idUsuario="idUsuario" ref="createNotas" v-on:ultima-nota="cargarUltimaNota(idUsuario)"></modalNotasCreate>  
        <div class="card-header">
            <div class="row">
          <div class="col-6 ">
            Notas Enfermeria 
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button  class="btn btn-primary  btn-xs" type="button" @click="newNota();" v-if="_can('nota.enfermeria')">
                Nuevo &nbsp;<i class="fas fa-plus"></i>
            </button>           
          </div>
        </div>
        </div>
        <div class="card-body">
             <span class="d-block small" >Ultima Nota : <span v-if="Object.keys(ultima_nota).length">{{ultima_nota.fecha}}</span><span v-else class="text-danger">Sin datos...</span> </span>
            <button @click="historialNotas();" type="button" class="btn btn-info  btn-xs">Ver Notas <i class="far fa-eye fa-fw"></i></button>                
        </div>
    </main>
</template>
<script>

import notasEnfermeriaService from '../../../../services/notasEnfermeriaService';
import $ from 'jquery';
import modalNotasList from "./modalNotasList";
import modalNotasCreate from "./modalNotasCreate";
export default {
    props: ['idUsuario', 'usuario'],
    components:{modalNotasList,modalNotasCreate},
    data(){
        return {
            notas:{},
            ultima_nota:{},
            id_usuario: '',
            
        }
    },
    methods: { 
        async cargarUltimaNota(id){
            const response = await notasEnfermeriaService.showByLast(id);
            this.ultima_nota= response.data;
        },
        async historialNotas(){
            $('#modalNotasList').modal('show');
            this.$refs.listNotas.getNotas();
        },
        async newNota(){
            this.$refs.createNotas.open();
        },
    },
    watch:{
        idUsuario(){
            this.cargarUltimaNota(this.idUsuario);
        }
    },
    async created() {
        //this.cargarUltimaNota(this.idUsuario);
        await this.cargarUltimaNota(this.idUsuario);
       
    }
}
</script>