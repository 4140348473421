<template>
  <main>
    <div class="card-header">
      Citologías
    </div>
    <div class="list-group list-group-flush " v-if="Object.keys(muestras).length">
      <li class="list-group-item list-group-flush d-flex justify-content-between align-items-center"  v-for="(muestra, i) in muestras" :key="`muestra_${i}`" >
        Fecha de la toma: {{ muestra.fecha_lectura | formatDate}} 
        <button v-if="muestra.resultado" class="btn btn-primary btn-xs mx-2" type="button" @click="printResults(muestra.id)">
          Ver Resultados <i class="fas fa-eye fa-fw"></i>
        </button>
        <span v-else class="badge badge-danger-soft">Sin resultados</span>
      </li>
    </div>
    <div v-else class="alert alert-danger" role="alert">Sin datos...</div>
  </main>
</template>
<script>

import tomaMuestrasService from "@/services/tomaMuestrasService";
import citologiaService from "../../../services/citologiaService";

export default {
  props: ['idUsuario'],
  data() {
    return {
      muestras: [],
    }
  },
  methods: {
    async cargar() {
      let response = await tomaMuestrasService.index({
        id_usuario: this.idUsuario,
        without_results: true
      });
      this.muestras = response.data;
    },
    printResults(id) {
      window.open(citologiaService.print(id));
    },
  },
  watch: {
    idUsuario: function () {
      this.cargar();
    }
  },
  created() {
    this.cargar();
  }
}
</script>