<template>
    <div class="modal fade" id="modalCertificateList" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><i class="far fa-clipboard fa-fw text-primary"></i> Certificados Médicos</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <data-grid :data="pagination"
                           @changePage="changePage($event)"
                           @perPageChange="perPageChange($event)"
                           @search="search($event)">
                            <template #grid>
                                <grid-table>
                                    <template #head>
                                        <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                    </template>
                                    <template #body>
                                        <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                            <grid-cell class="text-center small">
                                                {{item.id}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.date_certificate | formatDate}} {{item.hour_certificate | timeFormat}}
                                            </grid-cell>
                                            <grid-cell class="text-center ">
                                                <span v-if="item.user">{{item.user.name}}</span>
                                            </grid-cell>
                                            <grid-cell class="text-center   ">
                                                <div class="form-group ">
                                              <!--   <button @click="ver(item.id);" class="btn btn-outline-primary mx-2 lift shadow-sm btn-sm" title="ver nota" >
                                                    <i class="fa fa-eye"></i>
                                                </button> -->
                                                <button v-if="item.user_id===user.id" @click="deleteCeritificate(item.id);" class="btn btn-outline-danger lift shadow-sm btn-sm" title="eliminar">
                                                    <i class="fa fa-trash-alt"></i>
                                                </button>
                                               <!--  <button  v-if="item.id_user===user.id" @click="edit(item.id);" class="btn btn-outline-success lift shadow-sm btn-xs" title="editar">
                                                    <i class="fa fa-edit fa-fw"></i> Editar
                                                </button> -->
                                                <button @click="printCertificate(item.id);" class="btn btn-outline-info lift mx-2 shadow-sm btn-sm" title="Imprimir">
                                                    <i class="fa fa-print fa-fw"></i>
                                                </button>
                                            </div>
                                            </grid-cell>
                                        </grid-row>
                                    </template>
                                </grid-table>
                            </template>
                    </data-grid>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import medicalCertificateService from "../../../../services/medicalCertificateService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';

import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../../components/common/utilities/DataGrid/GridCell";
import Swal from "sweetalert2";
export default {
    props: ['certificates','idUsuario','usuario'],
    components: {
        GridCell, GridRow, GridCellHeader, GridTable, DataGrid
    },
    data(){
        return {
            headers : [
               '#',
               'Fecha',
               'Profesional',
               'Opciones'
           ],
           pagination : {
               data : [],
               current_page : 1,
               page : 1,
               per_page : 5,
               search : '',
               total: '',
           }
        }
    },
    methods:{
        async deleteCeritificate(id) {
            const result = await Swal.fire({
                title :  "¿Esta seguro de eliminar este certificado ?",
                text : "Recuerde que no se pueden revertir los cambios ",
                showCancelButton : true,
                showConfirmButton : true,
                confirmButtonText : 'Sí',
                cancelButtonText : 'No',
                icon : "question",
                showLoaderOnConfirm : true,
                preConfirm: async () => {
                    try{
                        this.LoaderSpinnerShow();
                        await medicalCertificateService.delete(id);
                        this.LoaderSpinnerHide();
                    }catch (e) {
                        console.error(e);
                        this.LoaderSpinnerHide();
                        Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                    }
                },
            });

            if(result.isConfirmed){
                Toast.fire({
                    icon: 'success',
                    title: 'Datos eliminados con exito'
                });
                $('#modalCertificateList').modal('hide');
                this.$emit('last-certificate');
            }            
        },
        printCertificate(id){
            window.open(medicalCertificateService.print(id));
        },
        async getCertificates(){
            try {
                
                const response = (await medicalCertificateService.showByIdUser(this.pagination.search, this.pagination,this.idUsuario))?.data;
                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;
                
            }catch (e) {
                console.error(e);
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getCertificates();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getCertificates();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getCertificates();
        },
        async open(){
            this.$nextTick(async () => {
                $('#modalCertificateList').modal('show');
                this.getCertificates();
            });
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>

