<template>
    <main>
        <!-- Modal -->
        <modalCreate :usuario="usuario" :idUsuario="idUsuario" ref="createCertificate" v-on:last-certificate="getLastCertificate(idUsuario)"></modalCreate>  
        <!-- Modal -->
        <modalList :certificates="certificates" :usuario="usuario" :idUsuario="idUsuario" ref="listCertificate" v-on:last-certificate="getLastCertificate(idUsuario)"></modalList>  
        <div class="card-header">
            <div class="row">
                <div class="col-6 ">
                    Certificados Médicos 
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <button  class="btn btn-primary  btn-xs" type="button" @click="newCertificate();" v-if="_can('certificate.create')">
                        Nuevo &nbsp;<i class="fas fa-plus"></i>
                    </button>           
                </div>
            </div>
        </div>
        <div class="card-body">
            <span class="d-block small" >Ultimo Certificado : <span v-if="Object.keys(lastCertificate).length">{{lastCertificate.date_certificate | formatDate}} {{lastCertificate.hour_certificate | timeFormat}}</span><span v-else class="text-danger">Sin datos...</span> </span>
            <button @click="historyCertificates();" type="button" class="btn btn-info  btn-xs">Ver Certificado <i class="far fa-eye fa-fw"></i></button>                
        </div>
    </main>
</template>
<script>

import medicalCertificateService from '../../../../services/medicalCertificateService';
import modalCreate from "./modalCreate";
import modalList from "./modalList";
export default {
    props: ['idUsuario', 'usuario'],
    components:{modalCreate,modalList},
    data(){
        return {
            certificates:{},
            lastCertificate:{},
            id_usuario: '',
        }
    },
    methods: { 
        async getLastCertificate(id){
            const response = await medicalCertificateService.showByLast(id);
            this.lastCertificate= response.data;
        },
        async historyCertificates(){
            this.$refs.listCertificate.open();
        },
        async newCertificate(){
            this.$refs.createCertificate.open();
        },
    },
    watch:{
        idUsuario(){
            this.getLastCertificate(this.idUsuario);
        }
    },
    async created() {
        await this.getLastCertificate(this.idUsuario);
       
    }
}
</script>