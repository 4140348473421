import Service from "@/services/Service";
const baseurl = '/api/citologia/resultado';
export default {
    store(data){
        return Service.post(`${baseurl}/store`, data);
    },
    index(search = '', pagination = {}){

        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(pagination['per_page'] ? {per_page : pagination['per_page']} : {}),
            ...(pagination['page'] ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };

        return Service.get(`${baseurl}?`, {
            params : {
                ...params
            }
        });
    },
    print(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}`
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    update(data){
        return Service.put(`${baseurl}/update`, data);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    }
}
