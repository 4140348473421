<template>
    <div class="modal fade" id="modalCertificateCreate" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Certificado Médico</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Fecha <small class="text-danger">*</small></label>
                                <input v-model="date_certificate" class="form-control form-control-sm" type="date" :class="{
                                    'is-invalid': $v.date_certificate.$error && $v.date_certificate.$dirty
                                }" />
                            </div>
                            <div class="mt-2 col-lg-4 col-md-12 col-sm-12">
                                <label class="small mb-1 font-weight-bolder">Hora<small class="text-danger">*</small></label>
                                <input type="time" class="form-control form-control-sm" v-model="hour_certificate"
                                    :class="{ 'is-invalid': $v.hour_certificate.$error && $v.hour_certificate.$dirty }">
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div class="form-group row">
                                <label  class="font-weight-bolder"> Descripción </label>
                                <div class="col-sm-12">
                                    <textarea cols="30" rows="10" class="form-control" v-model="description" :class="{'is-invalid':$v.description.$error && $v.description.$dirty}"></textarea>
                                    <span class="text-danger text-sm" v-if="!$v.description.minLengthValue">Debe ingresar un minimo de 10 caracteres</span>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success btn-sm" @click="save();">Guardar<i class="fa fa-save fa-fw"></i></button>
                        <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import medicalCertificateService from "../../../../services/medicalCertificateService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
import {required,minLength} from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default {
    props: ['idUsuario','usuario'],
    data(){
        return {
            description:'',
            date_certificate:'',
            hour_certificate:''
        }
    },
    validations (){
        return {
            date_certificate: {required},
            hour_certificate: {required},
            description: {required,
                minLengthValue: minLength(10)
            }
        }
    },
    methods:{
        async save() {
            try {     

                this.$v.$touch();

                if(this.$v.$invalid) return;

                let certificate ={
                    description:this.description,
                    date_certificate:this.date_certificate,
                    hour_certificate:this.hour_certificate,
                    id_usuario:this.idUsuario
                }

                this.LoaderSpinnerShow();
                await medicalCertificateService.store(certificate);
                this.LoaderSpinnerHide();

                this.cleanInputs();

                $(`#modalCertificateCreate`).modal('hide');
                this.$emit('last-certificate');
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                
            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            } 
        },
        cleanInputs(){
            this.description='';
            this.date_certificate='';
            this.hour_certificate='';
        },
        open(){
            this.$nextTick(async () => {
                $('#modalCertificateCreate').modal('show');
            });
        }
    },
    created(){
        const date_now = dayjs();
        this.date_certificate = date_now.format("YYYY-MM-DD");
        this.hour_certificate = date_now.format("HH:mm");
    }
}
</script>

