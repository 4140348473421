<template>
    <div class="modal fade" id="modalNotasList" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><i class="far fa-clipboard fa-fw text-primary"></i> Notas</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <data-grid :data="pagination"
                           @changePage="changePage($event)"
                           @perPageChange="perPageChange($event)"
                           @search="search($event)">
                            <template #grid>
                                <grid-table>
                                    <template #head>
                                        <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                    </template>
                                    <template #body>
                                        <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                            <grid-cell class="text-center small">
                                                {{item.id}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.fecha | formatDate}} {{item.hora | timeFormat}}
                                            </grid-cell>
                                            <grid-cell class="text-center ">
                                                <span v-if="item.user">{{item.user.name}}</span>
                                            </grid-cell>
                                            <grid-cell class="text-center   ">
                                                <div class="form-group ">
                                                <button @click="ver(item.id);" class="btn btn-outline-primary mx-2 lift shadow-sm btn-sm" title="ver nota" >
                                                    <i class="fa fa-eye"></i>
                                                </button>
                                                <button v-if="item.id_user===user.id" @click="eliminarNota(item.id);" class="btn btn-outline-danger lift shadow-sm btn-sm" title="eliminar">
                                                    <i class="fa fa-trash-alt"></i>
                                                </button>
                                                <button  v-if="item.id_user===user.id" @click="edit(item.id);" class="btn btn-outline-success lift shadow-sm btn-xs" title="editar">
                                                    <i class="fa fa-edit fa-fw"></i> Editar
                                                </button>
                                                <button @click="imprimir(item.id);" class="btn btn-outline-info lift mx-2 shadow-sm btn-sm" title="imprimir">
                                                    <i class="fa fa-print fa-fw"></i>
                                                </button>
                                            </div>
                                            </grid-cell>
                                        </grid-row>
                                    </template>
                                </grid-table>
                            </template>
                    </data-grid>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import notasEnfermeriaService from "../../../../services/notasEnfermeriaService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';

import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../../components/common/utilities/DataGrid/GridCell";
export default {
    props: ['notas','idUsuario','usuario'],
    components: {
        GridCell, GridRow, GridCellHeader, GridTable, DataGrid
    },
    data(){
        return {
            headers : [
               '#',
               'Fecha',
               'Profesional',
               'Opciones'
           ],
           pagination : {
               data : [],
               current_page : 1,
               page : 1,
               per_page : 5,
               search : '',
               total: '',
           }
        }
    },
    methods:{
        async eliminarNota(id) {
            this.LoaderSpinnerShow();
            await notasEnfermeriaService.delete(id);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'success',
                title: 'Datos eliminados con exito'
            });
            $('#modalNotasList').modal('hide');
            this.$emit('ultima-nota');
        },
        ver(id){
            $('#modalNotasList').modal('hide');
            this.$router.push({
                name:'ver.notas.enfermeria',
                params:{'id':id}
            })
        },
        imprimir(id){
            window.open(notasEnfermeriaService.print(id));
        },
        async getNotas(){
            try {
                
                const response = (await notasEnfermeriaService.showByIdUser(this.pagination.search, this.pagination,this.idUsuario))?.data;
                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;
                
            }catch (e) {
                console.error(e);
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getNotas();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getNotas();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getNotas();
        },
        edit(id){
            $('#modalNotasList').modal('hide');
            this.$router.push({
                name:'edit.notas.enfermeria',
                params:{'id':id}
            })
        },
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>

