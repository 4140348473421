<template>
    <div class="modal fade" id="modalNotasTrabajoSocialCreate" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Notas de Trabajo social</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label font-weight-bolder"> Nota </label>
                        <div class="col-sm-12">
                            <textarea cols="30" rows="5" class="form-control" v-model="nota"
                                :class="{ 'is-invalid': $v.nota.$error && $v.nota.$dirty }"></textarea>
                        </div>
                    </div>
                </div>
            

            <div class="modal-footer">
                <button type="button" class="btn btn-success btn-sm" @click="guardar();">Guardar<i
                        class="fa fa-save fa-fw"></i></button>
                <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i
                        class="fa fa-times-circle fa-fw"></i></button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import notasTrabajoSocialService from "../../../../services/notasTrabajoSocialService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
import { required } from "vuelidate/lib/validators";

export default {
    props: ['notas', 'idUsuario', 'usuario'],
    data() {
        return {
            nota: '',
        }
    },
    validations() {
        return {
            nota: { required }
        }
    },
    methods: {
        async guardar() {
            try {

                this.$v.$touch();

                if (this.$v.$invalid) return;

                let notas = {
                    id_usuario: this.idUsuario,
                    nota: this.nota,
                }

                this.LoaderSpinnerShow();
                await notasTrabajoSocialService.store(notas);
                this.LoaderSpinnerHide();
                this.limpiarCampos();

                $(`#modalNotasTrabajoSocialCreate`).modal('hide');
                this.$emit('ultima-nota');

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos() {
            this.nota = '';
        },
        open() {
            this.$nextTick(async () => {
                $('#modalNotasTrabajoSocialCreate').modal('show');
            });
        }

    },
}
</script>
