<template>
    <main>
        <!-- Modal -->
        <modalNotasTrabajoSocialList :notas="notas" :usuario="usuario" :idUsuario="idUsuario" ref="listNotasTrabajoSocial" v-on:ultima-nota="cargarUltimaNota(idUsuario)"></modalNotasTrabajoSocialList>  
        <!-- Modal -->
        <modalNotasTrabajoSocialCreate :notas="notas" :usuario="usuario" :idUsuario="idUsuario" ref="createNotasTrabajoSocial" v-on:ultima-nota="cargarUltimaNota(idUsuario)"></modalNotasTrabajoSocialCreate>  
        <div class="card-header">
            <div class="row">
          <div class="col-6 ">
            Notas de Trabajo Social 
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button  class="btn btn-primary  btn-xs" type="button" @click="newNota();" v-if="_can('nota.trabajoSocial')">
                Nuevo &nbsp;<i class="fas fa-plus"></i>
            </button>           
          </div>
        </div>
        </div>
        <div class="card-body">
             <span class="d-block small" >Ultima Nota : <span v-if="Object.keys(ultima_nota).length">{{ultima_nota.created_at | formatDateTime}}</span><span v-else class="text-danger">Sin datos...</span> </span>
            <button @click="historialNotas();" type="button" class="btn btn-info  btn-xs">Ver Notas <i class="far fa-eye fa-fw"></i></button>                
        </div>
    </main>
</template>
<script>

import notasTrabajoSocialService from '../../../../services/notasTrabajoSocialService';
import $ from 'jquery';
import modalNotasTrabajoSocialList from "./modalNotasTrabajoSocialList";
import modalNotasTrabajoSocialCreate from "./modalNotasTrabajoSocialCreate";
export default {
    props: ['idUsuario', 'usuario'],
    components:{modalNotasTrabajoSocialList,modalNotasTrabajoSocialCreate},
    data(){
        return {
            notas:{},
            ultima_nota:{},
            id_usuario: '',
            
        }
    },
    methods: { 
        async cargarUltimaNota(id){
            const response = await notasTrabajoSocialService.showByLast(id);
            this.ultima_nota= response.data;
        },
        async historialNotas(){
            $('#modalNotasTrabajoSocialList').modal('show');
            this.$refs.listNotasTrabajoSocial.getNotas();
        },
        async newNota(){
            this.$refs.createNotasTrabajoSocial.open();
        },
    },
    watch:{
        idUsuario(){
            this.cargarUltimaNota(this.idUsuario);
        }
    },
    async created() {
        //this.cargarUltimaNota(this.idUsuario);
        await this.cargarUltimaNota(this.idUsuario);
       
    }
}
</script>